<template>
  <div>
    <!-- header -->
    <div class="nav">
      <div class="logo_bgc"></div>
      <img
        class="logo"
        :src="require('@/assets/' + $t('navLogo'))"
        alt="logo.png"
      />
      <div class="change_language" @click="changeLanguage">
        {{ $t("language") }}
      </div>
      <div class="header_btn">
        <button class="header_btn_signup">
          <span>{{ $t("signUp") }}</span>
        </button>
        <button class="header_btn_login">
          <span>{{ $t("login") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {};
  },
  methods: {
    changeLanguage() {
      const currentLocale = this.$i18n.locale;
      const nextLocale = currentLocale === "en" ? "zh" : "en";
      localStorage.setItem("languageSet", nextLocale);
      this.$i18n.locale = nextLocale;
      document.title = this.$t("title");
    },
  },
};
</script>

<style scoped>
/* 顶部导航栏 */
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo_bgc {
  position: absolute;
  width: 12.5rem;
  height: 3.75rem;
  background: radial-gradient(50% 50% at 50% 50%, #4f7fff 0%, #ac6aff 100%);
  mix-blend-mode: normal;
  opacity: 0.7;
  filter: blur(3.75rem);
  font-size: 2rem;
}

.logo {
  width: 12.5rem;
}

.change_language {
  margin-left: auto;
  margin-right: 35px;
  cursor: pointer;
  text-decoration: underline;
}

.header_btn {
  display: flex;
  align-items: center;
  width: 17.375rem;
  font-size: 0.7rem;
}

.header_btn_signup,
.header_btn_login {
  opacity: 0.9;
  box-shadow: 0px 0.125rem 0.5rem rgba(0, 0, 0, 0.06);
  border-radius: 2rem;
  padding: 0.5rem 1.375rem !important;
  margin-right: 0.6875rem;
  font-size: 0.6875rem;
  border: 0px;
  cursor: pointer;
}

.header_btn_signup {
  background: #ffffff;
}

.header_btn_login {
  background: linear-gradient(180deg, #4f80ff 0%, #896bff 100%);
}

.header_btn_signup span {
  background-image: linear-gradient(180deg, #4f80ff 0%, #896bff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header_btn_login span {
  color: #ffffff;
}
</style>