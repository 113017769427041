<template>
  <div class="type_content">
    <div class="robot">
      <img width="48px" src="@/assets/robot.png" alt="" />
      <div class="robot_call">
        <div>HI,</div>
        <div>{{ $t("create") }}</div>
      </div>
    </div>
    <div class="type_list">
      <div
        class="type_list_item"
        v-for="(item, index) in $t('typeList')"
        :key="index"
      >
        <div class="type_list_item_img">
          <img :src="require('@/assets/' + item.image)" alt="" />
          <div class="type_list_item_usesNumber">
            {{ item.usesNumber }}
          </div>
        </div>
        <div class="type_list_item_title">{{ item.title }}</div>
        <div class="type_list_item_label">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "indexContent",
  data() {
    return {};
  },
};
</script>

<style scoped>
.type_content {
  padding: 0 4rem;
}

.robot {
  display: flex;
  align-items: center;
  padding-top: 2em;
}

.robot_call {
  margin-left: 20px;
  text-align: left;
  font-weight: 800;
  font-size: 1rem;
  font-family: "YouSheBiaoTiHei";
  font-style: normal;
}

.robot_call div {
  background: linear-gradient(180deg, #4f80ff 0%, #896bff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.type_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  padding: 2rem 0;
}

.type_list_item {
  width: calc((100% - 9rem) / 4);
}

.type_list_item_img {
  position: relative;
}

.type_list_item_img img {
  object-fit: cover;
  width: 100%;
  height: 20rem;
  border-radius: 2rem;
}

.type_list_item_usesNumber {
  position: absolute;
  left: 50%;
  bottom: 0.8rem;
  transform: translate(-50%, 0);
  width: calc(100% - 1rem);
  color: #fff;
  font-size: 0.6rem;
  border-radius: 0.8rem;
  padding: 0.4rem 0;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
}

.type_list_item_title {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 800;
  color: #000000;
  font-style: italic;
}

.type_list_item_label {
  margin-top: 0.2rem;
  margin-bottom: 0.8rem;
  font-size: 0.7rem;
  color: #999;
}
</style>