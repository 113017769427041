<template>
  <div>
    <div class="copyright">
      <div style="margin-right: 5px">
        {{ $t("Copyright") }}
      </div>
      <span onclick="window.open('https://beian.miit.gov.cn/')">
        京ICP备2023018248号
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "introduce",
  props: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 1rem;
  font-size: 0.5rem;
  color: #9c9fa6;
}

.copyright span {
  cursor: pointer;
}
</style>